// import Cta from './components/CTAsection/Cta';
import Footer from './components/footersection/Footer';
import Hero from './components/herosection/Hero';
// import MarketStatistic from './components/marketstactisticsection/MarketStatistic';
import Navbar from './components/navbarsection/Navbar';
import Why from './components/whysection/Why';

function App() {
  return (
    <div className='bg-[#0D0D2B] box-border font-rubik'>
      <div class="bg-indigo-900 text-center py-4 lg:px-4">
        <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
          <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Be aware</span>
          <span class="font-semibold mr-2 text-left flex-auto">Website is under construction 🏗️</span>
        </div>
      </div>
      <div className='bg-stars'>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
      </div>

      <Navbar />
      <Hero />
      <Why />
      {/* <MarketStatistic /> */}
      {/* <Cta /> */}
      <Footer />
    </div>
  );
}

export default App;
