import { FooterContent } from "./FooterContent"
import { motion } from "framer-motion"

import Twitter from '../../assests/icons/twitter.png'
import Telegram from '../../assests/icons/telegram.svg'

export default function Footer() {
  return (
    <footer className="container mx-auto flex flex-col bg-[#0D0D2B] lg:px-[80px] px-[30px] pb-[80px] text-white md:text-left text-center">
      <div className="flex flex-col-reverse md:flex-row justify-between mt-[120px]">
        <div>
          <p>{FooterContent.Copyright}</p>
        </div>
        <div className="flex flex-row gap-[10px] md:mb-0 mb-[20px] justify-center">
          <motion.div
            whileHover={{scale:1.1}}
            >
              <a href="https://twitter.com/k0styanpro" target="_blank" rel="noreferrer">
                <img className="cursor-pointer" width={24} height={24} src={Twitter} alt="card" />
              </a>
              
            </motion.div>
            <motion.div
            whileHover={{scale:1.1}}
            >
              <a href="https://t.me/k0styanpro" target="_blank" rel="noreferrer">
              <img className="cursor-pointer" width={24} height={24} style={{ color: 'red'}} src={Telegram} alt="card" />
              </a>
              
            </motion.div>
        </div>
      </div>
    </footer>
  )
}