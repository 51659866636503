import logo from '../../assests/icons/logo-hash.svg'
export const NavbarContent= {
  logo: {
    icon: logo,
    label: 'GetHash'
  },
  links: [
    {
      link: 'About',
      id: '1'
    },
    {
      link: 'Validator info',
      id: '2'
    },
    {
      link: 'Advantages',
      id: '3'
    },
    {
      link: 'FAQ',
      id: '4'
    }
  ],
  buttons:{
    Question: 'Question?'
  }
}

