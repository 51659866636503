
import { motion } from 'framer-motion'
import { staggerContainer, staggerTextContainer, fadeInUp, zoomOut } from '../../animation/MotionVariants'
import { HeroContent } from './HeroContent'
export default function Hero() {
  return (
    <motion.section className="container mx-auto text-white flex lg:flex-row flex-col lg:items-center lg:px-[80px] px-[30px] py-[25px]"
      variants={staggerContainer}
      initial={"initial"}
      animate={"animate"}
    >
      <motion.div className="lg:w-1/2 lg:mt-[20px] flex flex-col items-center text-center lg:block lg:text-left"
        variants={staggerTextContainer}
        initial={"initial"}
        animate={"animate"}
      >
        <motion.p className="
            text-white
            bg-opacity-10
            bg-white
            max-w-[230px]
            flex
            items-center
            p-[8px]
            rounded-[32px]
            text-[14px]
          "
          variants={fadeInUp}

        >
          <span
            className="
          lg:text-[16px]
          text-[12px]
          text-base
          text-[#0D0D2B]
          bg-white
          py-[8px]
          px-[15px]
          rounded-[32px]
          mr-[16px]
          "
          >
            {HeroContent.text.discountTag.span}
          </span>
          {HeroContent.text.discountTag.p}
        </motion.p>
        <motion.h1 className="xl:text-[56px] lg:text-[45px] md:text-[40px] text-[30px] font-semibold my-[24px]"
          variants={fadeInUp}
        >
          {HeroContent.text.h1}
        </motion.h1>
        <motion.p className="lg:w-3/5 md:text-[16px] text-[14px]"
          variants={fadeInUp}
        >
          <span className='text-[#c3c3c3]'>{HeroContent.text.p}</span>
        </motion.p>
        <motion.button className='flex items-center rounded-[32px] mt-[38px]'
          variants={fadeInUp}
          whileHover={{ scale: 1.05 }}
        >
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-sky-600 to-sky-500 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
            <button className="relative px-7 py-4 bg-gradient-to-r from-slate-900 via-sky-800 to-slate-900 rounded-lg leading-none flex items-center divide-x divide-gray-600">
              <span className="flex items-center space-x-5">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-indigo-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                </svg>

                <span className="pr-6 text-gray-100">{HeroContent.button.tag}</span>
              </span>
            </button>
          </div>
        </motion.button>
      </motion.div>
      <div className='lg:w-1/2 lg:min-w-[500px] lg:min-h-[600px] flex items-center mt-[3rem] lg:mt-[0]'>
        <motion.img className='w-full' src={HeroContent.illustration} alt="heroimage"
          variants={zoomOut}
        />
      </div>
    </motion.section>
  )
}