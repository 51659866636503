import arrow from '../../assests/icons/Vector.png'
import heroImage from '../../assests/image/hero-promo.png'

export const HeroContent = {
  text: {
    discountTag:{
      span: 'Zero-Fee',
      p: 'Join us today'
    },
    h1:'Start staking today, secure your assets and earn passive',
    p: 'Secure and reliable non-custodial SOL staking service',
  },
  button: {
    tag: 'Stake your SOL now',
    icon: arrow
  },
  illustration: heroImage
}