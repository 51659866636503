
import { motion } from 'framer-motion'
import { staggerContainer, fadeInUp, zoomOut } from '../../animation/MotionVariants'
import { WhyContent } from './WhyContent'

export default function Why() {
  return (
    <motion.section className='container mx-auto text-white py-[50px] my-[50px] lg:px-[80px] px-[30px]'
      variants={staggerContainer}
      initial={"initial"}
      viewport={{once:true, amount:0.9}}
      whileInView={"animate"}
    >
      <motion.div className='flex flex-col-reverse lg:flex-row justify-between text-white items-center lg:gap-[100px]'
        variants={staggerContainer}
        initial={"initial"}
        viewport={{once:true, amount:0.9}}
        whileInView={"animate"}
      >
        <div className='lg:w-[50%]'>
          <motion.img className='w-full' src={WhyContent.Illustration} alt="illustration" variants={zoomOut}/>
        </div>
        <motion.div className='lg:w-[50%]' variants={fadeInUp}>
          <h2 className='lg:text-[40px] text-[30px] font-semibold'>{WhyContent.text.h2}</h2>
          <p className='text-[#E0E0E0] max-w-[400px] md:text-[16px] text-[14px]'>{WhyContent.text.p}</p>
          <motion.button className='flex items-center bg-[#0a6495]  py-[16px] px-[24px] rounded-[32px] mt-[32px]'
          variants={fadeInUp}
          whileHover={{scale:1.1}}
          >
          {WhyContent.text.button}
          </motion.button>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}